const links = { // TODO remove title from links with tags
  login: { path: 'login', title: 'Логин' },
  loginStatus: { path: 'login-status', title: 'Логин' },
  loginSuccess: { path: 'login-success', title: 'Логин' },
  loginError: { path: 'login-error', title: 'Логин' },
  logout: { path: 'logout', title: 'Логаут' },
  confirmEmail: { path: 'confirm-email/:activationCode', title: 'Подтвердить email' },
  updatePassword: { path: 'update-password', title: 'Изменить пароль' },
  page403: { path: 'page403', title: 'Страница недоступна' },
  page404: { path: 'page404', title: 'Страница не существует' },
  profile: { path: 'user-profile', title: 'Профиль', type: 'vue' },
  reactProfile: {
    root: { path: 'profile', title: 'Профиль' },
    addCounter: { path: 'add-counter' },
    editCounter: { path: 'edit-counter/:counterId' },
    addPulseCounter: { path: 'add-pulse-counter' },
    editPulseCounter: { path: 'edit-pulse-counter/:counterId' },
    counterDetails: {
      root: { path: 'counter-details/:counterId' },
      editMonitor: { path: 'edit-monitor/:monitorId' },
      addMonitor: { path: 'add-monitor' },
      editSchedule: { path: 'edit-schedule/:scheduleId' },
      addSchedule: { path: 'add-schedule' },
    },
    editMonitor: { path: 'edit-monitor/:monitorId' },
  },
  home: { path: '', title: 'Главная страница' },
  weather: { path: 'weather', title: 'Погода' },
  createRoute: { path: 'create-route', title: 'Маршрут' },
  privacy: { path: 'privacy', title: 'Публичная оферта' },
  support: { path: 'support', title: 'Техническая поддержка' },
  noTransport: { path: 'no-transport', title: 'Транспорт скоро будет' },
  budget: { path: 'budget', title: 'Бюджет района для граждан', tag: 'CITY_BUDGET' },
  appeals: {
    root: { path: 'appeals', title: 'Городские проблемы', tag: 'PROBLEMS' },
    add: { path: 'add' },
    item: {
      root: { path: ':itemId' },
      edit: { path: 'edit' },
      control: { path: 'control' },
    },
  },
  police: {
    root: { path: 'police', title: 'Участковые пункты', tag: 'POLICE' },
    item: { path: ':selectedItemId' },
  },
  elections: {
    root: { path: 'elections', title: 'Избирательные округа', tag: 'VOTE_DISTRITS' },
    item: { path: ':selectedItemId' },
  },
  institutions: {
    root: { path: 'institutions', title: 'Учреждения социальной сферы', tag: 'INSTITUTIONS' },
    item: { path: ':selectedItemId' },
  },
  edu: {
    root: { path: 'edu', title: 'Питание учащихся', tag: 'MP_EDU' },
    order: {
      root: { path: 'order' },
      success: { path: 'success/:orderId' },
      fail: { path: 'fail/:orderId' },
      cancel: { path: 'cancel/:orderId' },
      repeat: { path: 'repeat-order' },
    },
    privacy: { path: 'privacy', title: 'Условия использования', tag: 'MP_EDU' },
  },
  sport: {
    root: { path: 'sport', title: 'Спорт и досуг', tag: 'MP_SPORT' },
    conditions: { path: 'conditions' },
    tool: {
      root: { path: ':toolId' },
      bookingSchedule: { path: 'booking-schedule' },
      bookingSubscription: { path: 'booking-subscription' },
      searchSubscriber: { path: 'search-subscriber' },
    },
    order: {
      root: { path: 'order' },
      id: { path: ':itemId' },
    },
  },
  immortals: {
    root: { path: 'immortals', title: 'Бессмертный полк', tag: 'IMMORTALS' },
    item: { path: ':itemId' },
  },
  transport: {
    root: { path: 'transport', title: 'Общественный транспорт', tag: 'PUBLIC_TRANSPORT' },
    route:  {
      root: { path: 'route' },
      details: { path: ':routeId' },
    },
    stations:  {
      root: { path: 'stations' },
      details: { path: ':stationId' },
    },
  },
  accessibility: {
    root: { path: 'accessibility', title: 'Обеспечение мобильности', tag: 'PROVIDING_MOBILITY' },
  },
  medscan: {
    root: { path: 'medscan', title: 'Запись к врачу' },
    login: { path: 'login', title: 'Авторизация «Медассист»' },
    record: { path: 'record', title: 'Запись на прием' },
  },
  notification: {
    root: { path: 'notification', title: 'Уведомления' },
  },

  // VUE
  excavation: {
    root: { path: 'excavation', title: 'Земляные работы', tag: 'EXCAVATION', type: 'vue' },
  },
  roadEvents: {
    root: { path: 'road-events', title: 'ДТП', tag: 'ROAD_EVENTS', type: 'vue' },
  },
  rent: {
    root: { path: 'rent', title: 'Площади в аренду', tag: 'SPACES_FOR_RENT', type: 'vue' },
  },
  cleaning: { path: 'cleaning', title: 'Уборка города', tag: 'CLEARING', type: 'vue' },
  culture: {
    root: { path: 'culture', title: 'Достопримечательности', tag: 'CULTURE_OBJECTS', type: 'vue' },
    item: { path: ':itemId' },
  },
  medicine: {
    root: { path: 'medicine', title: 'Медицина', tag: 'MEDICINE', type: 'vue' },
    item: { path: 'info' },
  },
  news: {
    root: { path: 'news', title: 'Новости', type: 'vue', tag: 'NEWS' },
    item: { path: ':itemId' },
  },
  votings: {
    root: { path: 'votings', title: 'Опросы', type: 'vue', tag: 'VOTING' },
    item: { path: ':itemId' },
  },
  projects: {
    root: { path: 'projects', title: 'Проекты', type: 'vue', tag: 'CITY_PROJECTS' },
    planGv: { path: 'plan_gv', title: 'Проекты развития города', type: 'vue', tag: 'PROJECT_GV' },
    planIb: { path: 'plan_ib', title: 'Инициативные проекты', type: 'vue', tag: 'PROJECT_IB' },
    planSz: { path: 'plan_sz', title: 'Планы по благоустройству', type: 'vue', tag: 'PROJECT_SZ' },
    item: { path: ':itemId' },
  },
  events: {
    root: { path: 'events', title: 'Календарь событий', type: 'vue', tag: 'POSTER' },
    item: { path: ':itemId' },
  },
  serviceArea: {
    root: { path: 'service-area', title: 'Территории обслуживания УК', tag: 'SERVICE_DISTRICTS', type: 'vue' },
    item: { path: 'info' },
  },
  goodsAndServices: {
    root: { path: 'goods-and-services', title: 'Реклама СМП', tag: 'ADVERTISING', type: 'vue' },
    item: { path: 'item/:itemId' },
  },
  controlTKO: { path: 'control-tko', title: 'Контроль вывоза ТКО', type: 'vue', tag: 'GARBAGE' },
  statistics: { path: 'statistics', title: 'Статистика', type: 'vue', tag: 'STATS' },
  alerts: {
    root: { path: 'alerts', title: 'Оперативные оповещения', tag: 'TELEPHONE_MESSAGES', type: 'vue' },
    item: { path: 'alert/:itemId' },
  },
  initiatives: { path: 'innitiatives', title: 'Инициативы', tag: 'INITIATIVES', type: 'vue' },
  nto: { path: 'nto', title: 'Нестационарные ТО', tag: 'SELL_POINT_MOVE', type: 'vue' },

  admin: {
    root: { path: 'admin', title: 'Административный раздел', tag: 'DASHBOARD' },
    chat: { path: 'chat', title: 'Чат', tag: 'CHAT' },
    scud: { path: 'scud', title: 'СКУД', tag: 'SKYD' },
    appeals: {
      root: { path: 'appeals', title: 'Городские проблемы', tag: 'PROBLEMS' },
      item: { path: ':itemId' },
    },
    institutions: {
      root: { path: 'institutions', title: 'Учреждения социальной сферы', tag: 'INSTITUTIONS' },
      addObject: { path: 'add-object' },
      editObject: { path: 'edit-object/:objectId' },
    },
    police: {
      root: { path: 'police', title: 'Участковые пункты', tag: 'POLICE' },
      addArea: { path: 'add-area' },
      editArea: { path: 'edit-area/:areaId' },
      addPoliceman: { path: 'add-policeman' },
      editPoliceman: { path: 'edit-policeman/:policemanId' },
      addDocument: { path: 'add-document' },
      editDocument: { path: 'edit-document/:documentId' },
    },
    elections: {
      root: { path: 'elections', title: 'Избирательные округа', tag: 'VOTE_DISTRITS' },
      addDistrict: { path: 'add-district' },
      editDistrict: { path: 'edit-district/:districtId' },
      addArea: { path: 'add-area' },
      editArea: { path: 'edit-area/:areaId' },
      addDeputy: { path: 'add-deputy' },
      editDeputy: { path: 'edit-deputy/:deputyId' },
      addDocument: { path: 'add-document' },
      editDocument: { path: 'edit-document/:documentId' },
      addAddress: { path: 'add-address' },
      editAddress: { path: 'edit-address/:addressId' },
    },
    counters: {
      root: { path: 'counters', title: 'Приборы учета', tag: 'IOT' },
      addCounter: { path: 'add-counter' },
      editCounter: { path: 'edit-counter/:counterId' },
      addPulseCounter: { path: 'add-pulse-counter' },
      editPulseCounter: { path: 'edit-pulse-counter/:counterId' },
      counterDetails: {
        root: { path: 'counter-details/:counterId' },
        editMonitor: { path: 'edit-monitor/:monitorId' },
        addMonitor: { path: 'add-monitor' },
        editSchedule: { path: 'edit-schedule/:scheduleId' },
        addSchedule: { path: 'add-schedule' },
      },
      editMonitor: { path: 'edit-monitor/:monitorId' },
      addSupplier: { path: 'add-supplier' },
      editSupplier: { path: 'edit-supplier/:supplierId' },
      addConsumer: { path: 'add-consumer' },
      editConsumer: { path: 'edit-consumer/:consumerId' },
      addResource: { path: 'add-resource' },
      editResource: { path: 'edit-resource/:resourceId' },
    },
    users: {
      root: { path: 'users', title: 'Личный кабинет', tag: 'USERS' },
      addUser: { path: 'add-user' },
      editUser: {
        root: { path: 'edit-user/:userId' },
        viewRole: { path: 'view-role/:moduleTag' },
        addRole: { path: 'add-role' },
        editRole: { path: 'edit-role/:moduleTag' },
      },
    },
    settings: {
      root: { path: 'settings', title: 'Настройки', tag: 'PLATFORM_CONFIGS' },
      modules: {
        root: { path: 'modules', title: 'Модули' },
        serviceTransport: { path: 'service-transport', title: 'Служебный транспорт' },
        transport: { path: 'transport', title: 'Общественный транспорт' },
        marketplace: { path: 'marketplace', title: 'Маркетплейс' },
        counters: { path: 'counters', title: 'Счетчики' },
        appeals: { path: 'appeals', title: 'Городские проблемы' },
        clearing: { path: 'clearing', title: 'Уборка' },
        excavation: { path: 'excavation', title: 'Земляные работы' },
        videoControl: { path: 'full-video', title: 'Видеонаблюдение' },
        alerts: { path: 'alerts', title: 'Оперативные оповещения' },
        stats: { path: 'stats', title: 'Статистика' },
        weather: { path: 'weather', title: 'Погода' },
        rcc: { path: 'rcc', title: 'ИАС СЦ' },
        users: { path: 'users', title: 'Пользователи' },
        gisZkh: { path: 'gis-zkh', title: 'ГИС ЖКХ' },
        tko: { path: 'tko', title: 'ТКО' },
        asudd: { path: 'asudd', title: 'АСУДД' },
      },
    },
    edu: {
      root: { path: 'edu', title: 'Школьное питание', tag: 'MP_EDU' },
      suppliers: {
        root: { path: 'suppliers' },
        add: { path: 'add' },
        edit: { path: 'edit/:supplierId' },
      },
      categories: {
        root: { path: 'categories' },
        add: { path: 'add' },
        edit: { path: 'edit/:categoryId' },
      },
      schools: {
        root: { path: 'schools' },
        add: { path: 'add' },
        edit: { path: 'edit/:schoolId' },
      },
    },
    sport: {
      root: { path: 'sport', title: 'Спорт и досуг', tag: 'MP_SPORT' },
      add: { path: 'add' },
      area: {
        root: { path: ':areaId' },
        tools: { path: 'tools' },
        addTool: { path: 'add' },
        tool: {
          root: { path: ':toolId' },
          addTariff: { path: 'add-tariff' },
          tariff: { path: ':tariffId' },
          group: {
            root: { path: 'group' },
            item: {
              root: { path: ':groupId' },
              editGroup: { path: 'edit-group' },
            },
          },
          addGroup: { path: 'add-group' },
          schedule: { path: 'schedule' },
        },
      },
    },
    transport: {
      root: { path: 'transport', title: 'Общественный транспорт', tag: 'PUBLIC_TRANSPORT' },
      periods: {
        root: { path: 'periods' },
        add: { path: 'add' },
        edit: { path: 'edit/:periodId' },
      },
      routes: {
        root: { path: 'routes' },
        add: { path: 'add' },
        edit: { path: 'edit/:routeId' },
      },
      stations: {
        root: { path: 'stations' },
        add: { path: 'add' },
        edit: { path: 'edit/:stationId' },
      },
      drivers: {
        root: { path: 'drivers' },
        add: { path: 'add' },
        edit: { path: 'edit/:driverId' },
      },
      cashiers: {
        root: { path: 'cashiers' },
        add: { path: 'add' },
        edit: { path: 'edit/:cashierId' },
      },
    },
    serviceTransport: {
      root: { path: 'service-transport', title: 'Служебный транспорт', tag: 'SERVICE_TRANSPORT' },
      transport: {
        root: { path: 'transport' },
        add: { path: 'add' },
        edit: { path: 'edit/:transportId' },
      },
      owners: {
        root: { path: 'owners' },
        add: { path: 'add' },
        edit: { path: 'edit/:ownerId' },
      },
      tdps: {
        root: { path: 'tdps' },
        add: { path: 'add' },
        edit: { path: 'edit/:tdpId' },
      },
    },
    immortals: {
      root: { path: 'immortals', title: 'Бессмертный полк', tag: 'IMMORTALS' },
      item: { path: ':itemId' },
      add: { path: 'add' },
    },
    accessibility: {
      root: { path: 'accessibility', title: 'Обеспечение мобильности', tag: 'PROVIDING_MOBILITY' },
      item: { path: ':itemId' },
      add: { path: 'add' },
      edit: { path: 'edit/:itemId' },
    },
    asudd: {
      root: { path: 'asudd', title: 'АСУДД', tag: 'ASUDD' },
      trafficLights: {
        root: { path: 'trafficLights' },
        add: { path: 'add' },
        edit: { path: 'edit/:trafficLightId' },
      },
      roads: {
        root: { path: 'roads' },
        add: { path: 'add' },
        edit: { path: 'edit/:roadId' },
      },
    },

    // VUE
    constructions: {
      root: { path: 'constructions', title: 'Проекты строительства', tag: 'PROJECT_BLD', type: 'vue' },
    },
    culture: {
      root: { path: 'culture', title: 'Достопримечательности', tag: 'CULTURE_OBJECTS', type: 'vue' },
      editItem: { path: 'edit/:itemId' },
    },
    cleaning: {
      root: { path: 'cleaning', title: 'Уборка города', tag: 'CLEARING', type: 'vue' },
      add: { path: 'add-item' },
      editItem: { path: 'clear-edit/:itemId/false' },
    },
    medicine: { path: 'medicine', title: 'Медицина', tag: 'MEDICINE', type: 'vue' },
    gisZkh: { path: 'gis-zkh', title: 'ГИС ЖКХ', tag: 'GIS_JKH', type: 'vue' },
    controlTKO: {
      root: { path: 'control-tko', title: 'Контроль вывоза ТКО', tag: 'GARBAGE', type: 'vue' },
      camera: { path: 'camera/:itemId' },
    },
    serviceArea: { path: 'service-area', title: 'Территории обслуживания УК', tag: 'SERVICE_DISTRICTS', type: 'vue' },
    excavation: {
      root: { path: 'excavation', title: 'Земляные работы', tag: 'EXCAVATION', type: 'vue' },
      item: { path: 'work' },
    },
    communications: {
      root: { path: 'communications', title: 'Инженерные сети', tag: 'ENGINEERING_NETWORKS', type: 'vue' },
    },
    lighting: {
      root: { path: 'lighting', title: 'Освещение', tag: 'LIGHT', type: 'vue' },
    },
    videoControl: {
      root: { path: 'full-video', title: 'Видеонаблюдение', tag: 'CCTV', type: 'vue' },
    },
    nto: {
      root: { path: 'nto', title: 'Нестационарные ТО', tag: 'SELL_POINT_MOVE', type: 'vue' },
    },
    warningPoints: {
      root: { path: 'warning-points', title: 'Точки оповещения ГО и ЧС', tag: 'GOCHS', type: 'vue' },
    },
    mobileStations: {
      root: { path: 'mobile-stations', title: 'БС сотовой связи', tag: 'CELLULAR_BS', type: 'vue' },
    },
    kumi: {
      root: { path: 'kumi', title: 'Земляные участки КУМИ', tag: 'KUMI', type: 'vue' },
    },
    electric: {
      root: { path: 'electric', title: 'Мониторинг электропитания', tag: 'POWER_MONITORING', type: 'vue' },
    },
    elevators: {
      root: { path: 'elevators', title: 'Диспетчер лифтов', tag: 'ELEVATORS', type: 'vue' },
    },
    heating: {
      root: { path: 'heating', title: 'Отопление', tag: 'HEAT', type: 'vue' },
    },
    alerts: {
      root: { path: 'alerts', title: 'Оперативные оповещения', tag: 'TELEPHONE_MESSAGES', type: 'vue' },
      item: { path: 'details/:itemId' },
    },
    hydrants: {
      root: { path: 'hydrants', title: 'Пожарные гидранты', tag: 'FIRE_HYDRANTS', type: 'vue' },
    },
    roadEvents: {
      root: { path: 'road-events', title: 'Дорожная обстановка', tag: 'ROAD_EVENTS', type: 'vue' },
    },
    rent: {
      root: { path: 'rent', title: 'Площади в аренду', tag: 'SPACES_FOR_RENT', type: 'vue' },
      edit: { path: 'edit/:itemId' },
    },
    arm: {
      root: { path: 'arm', title: 'Дашборд', tag: 'DASHBOARD', type: 'vue' },
    },
    economy: {
      root: { path: 'economy', title: 'Городская экономика', tag: 'ECONOMY_DASHBOARD', type: 'vue' },
    },
    requests: {
      root: { path: 'requests', title: 'Поручения', tag: 'TASK_MNGR', type: 'vue' },
      project: { path: 'view-project/:itemId' },
      task: { path: 'task/:subItemId/:itemId' },
    },
    yards: {
      root: { path: 'yards', title: 'Благоустройство дворов', tag: 'YARDS', type: 'vue' },
      area: { path: 'area-details/:itemId' },
      equipment: { path: 'equipment-details/:itemId' },
    },
    covid: {
      root: { path: 'covid', title: 'Коронавирус', tag: 'COVID', type: 'vue' },
    },
  },
  // Laravel admin
  manager: {
    root: { path: 'manager', title: 'manager', tag: 'CITY_PROJECTS' },
    projectGv: { root: { path: 'site-items?module_id=4', title: 'projectGv', tag: 'PROJECT_GV' } },
    projectIb: { root: { path: 'site-items?module_id=2', title: 'projectIb', tag: 'PROJECT_IB' } },
    projectSz: { root: { path: 'site-items?module_id=1', title: 'projectSz', tag: 'PROJECT_SZ' } },
    projectBld: { root: { path: 'projectBld', title: 'projectBld', tag: 'PROJECT_BLD' } },
    votes: { root: { path: 'votes', title: 'votes', tag: 'VOTING' } },
    news: { root: { path: 'news', title: 'news', tag: 'NEWS' } },
    afisha: { root: { path: 'events', title: 'events', tag: 'POSTER' } },
    advertising: { root: { path: '', title: 'advertising', tag: 'ADVERTISING' } },
  },
}


export default links
